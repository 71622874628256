import * as Icons from "@mui/icons-material";
import { Alert, Snackbar, SnackbarOrigin } from "@mui/material";
import React from "react";
import "./Notification.scss";

/**
 * Props interface for this component.
 */
interface Props {
	/**
	 * The notification variant.
	 */
	variant: "success" | "info" | "warning" | "error";
	/**
	 * The message to display in the notification.
	 */
	message: React.ReactNode;
	/**
	 * The key to differentiate snackbars when multiple are to be displayed.
	 */
	snackbarKey: number;
	/**
	 * The open state of this notification.
	 */
	isOpen: boolean;
	/**
	 * The button displayed to the right of the notification the user
	 * can interact with.
	 */
	actionButton: React.ReactNode;
	/**
	 * Sets white-space: pre-line CSS rule
	 * Used by VIN feature request to display error details
	 */
	isMultiline?: boolean;
	/**
	 * Called when this notification is to close.
	 */
	onClose(): void;
	/**
	 * Optional anchor origin to position the Snackbar.
	 */
	anchorOrigin?: SnackbarOrigin;
	/**
	 * Optional inline styles to be applied to the component.
	 */
	style?: React.CSSProperties;
}

/**
 * Name of the {@link Notification} component
 */
export const COMPONENT_NAME = "Notification";

/**
 * Wrapper for a material UI snackbar to represent a notification in this app
 * @param props - component props.
 * @return the rendered component.
 */
export const Notification: React.FC<Props> = (props) => {
	const textClassNames = [`${COMPONENT_NAME}__text`];
	if (props.isMultiline) {
		// Gives white-space: pre-line and increases line height
		textClassNames.push(`${COMPONENT_NAME}__text--multiline`);
	}

	// Default anchorOrigin
	const anchorOrigin = props.anchorOrigin || { vertical: "top", horizontal: "center" };

	return (
		<div className={COMPONENT_NAME}>
			<Snackbar
				key={props.snackbarKey}
				anchorOrigin={anchorOrigin}
				open={props.isOpen}
				onClose={props.onClose}
				style={props.style}
			>
				<Alert
					onClose={props.onClose}
					severity={props.variant}
					aria-describedby="client-snackbar"
					variant="filled"
					iconMapping={{
						success: <Icons.Done />,
						warning: <Icons.Warning />,
						error: <Icons.Error />,
						info: <Icons.Info />
					}}
					action={props.actionButton}
				>
					<span id="client-snackbar" className={`${COMPONENT_NAME}__message`}>
						<span className={textClassNames.join(" ")}>{props.message}</span>
					</span>
				</Alert>
			</Snackbar>
		</div>
	);
};
Notification.displayName = COMPONENT_NAME;
