import * as Icons from "@mui/icons-material";
import { RequestManager } from "../../RequestManager";
import * as ApiModelTypes from "../../api/apiModelTypes";
import { Regions } from "../../utils/regionUtils";
import { NotificationEvent } from "../App";

/**
 * The available scenes.
 */
export type SCENES =
	| "home"
	| "vehicleManagement"
	| "policyCreation"
	| "userManagement"
	| "documentation"
	| "featureNames"
	| "vcrDashboard";

/**
 * Exportable Props
 */
export interface SceneProps {
	/**
	 * The request manager.
	 */
	requestManager: RequestManager;
	/**
	 * Called when a notification is to be displayed.
	 * @param notificationEvent - the notification event.
	 */
	onDisplayNotification(notificationEvent: NotificationEvent): void;

	/**
	 * The region that the user is in (NA/EU)
	 */
	region: Regions;
	/**
	 *  Called when the dirty state of the form changes.
	 * @param isDirty - the dirtiness of the form.
	 */
	onDirtyChanged(isDirty: boolean): void;
}

/**
 * The left menu configuration for a "scene".
 * A scene being some collection of views that are directly related.
 * "vins" for example is a scene which encompasses searching through
 * all vins and seeing a single vin in detail.
 */
export interface SceneConfig {
	/**
	 * The menu label.
	 */
	label: string;
	/**
	 * The url path to view this scene.
	 */
	path: string;
	/**
	 * The icon that the user can associate with this scene.
	 */
	icon: Icons.SvgIconComponent;
	/**
	 * Flag to indicate that the scene is to link to an external source.
	 */
	isExternal?: boolean;
	/**
	 * Optional permissions, if different than outer scene
	 */
	permissions?: ApiModelTypes.UserPermissions[];
}

/**
 * The configuration for a scene group.
 * This is for menu items that need to be grouped for
 * further organization. Users and Groups, for example.
 */
export interface SceneGroupConfig {
	/**
	 * The group menu item label.
	 */
	label: string;
	/**
	 * The scenes that belong to this group.
	 */
	scenes: Record<string, SceneConfig>;
}

/**
 * Scenes that the user is able to navigate to
 * in the left navigation menu.
 */
export const SCENE_CONFIGS: Record<SCENES, SceneGroupConfig | SceneConfig> = {
	home: {
		label: "Home",
		path: "/",
		icon: Icons.Home
	},
	vehicleManagement: {
		label: "Vehicle Management",
		scenes: {
			vins: { label: "Vehicles", path: "/vins", icon: Icons.DirectionsCar },
			streams: {
				label: "Data Streams",
				path: "/streams",
				icon: Icons.SyncAlt,
				permissions: [
					ApiModelTypes.UserPermissions.ADA_API_STREAM_READ_WRITE_NA,
					ApiModelTypes.UserPermissions.ADA_API_STREAM_READ_WRITE_EU
				]
			},
			"vehicle-testing": {
				label: "Testing & Verification",
				path: "/vehicle-testing",
				icon: Icons.Build
			}
		}
	},
	policyCreation: {
		label: "Policy Creation",
		scenes: {
			policies: {
				label: "Policies",
				path: "/policies",
				icon: Icons.Code
			},
			sensors: {
				label: "Sensors",
				path: "/sensors",
				icon: Icons.SettingsInputAntenna
			},
			synthetics: {
				label: "Synthetics",
				path: "/synthetics",
				icon: Icons.Functions
			},
			histograms: {
				label: "Histograms",
				path: "/histograms",
				icon: Icons.Assessment
			}
		}
	},
	userManagement: {
		label: "User Management",
		scenes: {
			users: {
				label: "Users",
				path: "/users",
				icon: Icons.Person
			},
			groups: {
				label: "Groups",
				path: "/groups",
				icon: Icons.People
			},
			matrix: {
				label: "Traceability Matrix",
				path: "/matrix",
				icon: Icons.AccountBox
			}
		}
	},
	documentation: {
		label: "Documentation",
		scenes: {
			swagger: {
				label: "Swagger API",
				path: "/api-docs",
				icon: Icons.MenuBook,
				isExternal: true
			},
			support: {
				label: "Support",
				path: "/support",
				icon: Icons.Support
			}
		}
	},
	featureNames: {
		label: "Feature Names",
		path: "/feature-names",
		icon: Icons.Assessment
	},
	vcrDashboard: {
		label: "VCR Recordings",
		path: "/vcr",
		icon: Icons.PlayArrow
	}
};

/**
 * Map of scene names to operation packages as defined by the API.
 * Partial because not all permissions explicitly relate to a scene category (Feature Approver lives under policy creation)
 */
export const SCENE_PERMISSION_MAP: Record<ApiModelTypes.UserPermissions, Array<SCENES>> = {
	[ApiModelTypes.UserPermissions.ADA_API_POLICY_READ_NA]: ["policyCreation", "featureNames"],
	[ApiModelTypes.UserPermissions.ADA_API_POLICY_WRITE_NA]: ["policyCreation", "featureNames"],
	[ApiModelTypes.UserPermissions.ADA_API_VIN_PROFILE_READ_NA]: [
		"vehicleManagement",
		"vcrDashboard"
	],
	[ApiModelTypes.UserPermissions.ADA_API_USER_READ_NA]: ["userManagement"],
	[ApiModelTypes.UserPermissions.ADA_API_USER_WRITE_NA]: ["userManagement"],
	[ApiModelTypes.UserPermissions.ADA_API_STREAM_READ_WRITE_NA]: ["userManagement"],
	[ApiModelTypes.UserPermissions.ADA_API_POLICY_ASSIGNMENT_NA]: ["vehicleManagement"],
	[ApiModelTypes.UserPermissions.ADA_API_POLICY_FEATURE_WRITE_NA]: [
		"policyCreation",
		"featureNames"
	],
	[ApiModelTypes.UserPermissions.ADA_API_FEATURE_WRITE_NA]: ["vehicleManagement"],
	[ApiModelTypes.UserPermissions.ADA_API_WEB_ACCESS_NA]: ["home", "documentation"],

	[ApiModelTypes.UserPermissions.ADA_API_POLICY_READ_EU]: ["policyCreation", "featureNames"],
	[ApiModelTypes.UserPermissions.ADA_API_POLICY_WRITE_EU]: ["policyCreation", "featureNames"],
	[ApiModelTypes.UserPermissions.ADA_API_VIN_PROFILE_READ_EU]: [
		"vehicleManagement",
		"vcrDashboard"
	],
	[ApiModelTypes.UserPermissions.ADA_API_USER_READ_EU]: ["userManagement"],
	[ApiModelTypes.UserPermissions.ADA_API_USER_WRITE_EU]: ["userManagement"],
	[ApiModelTypes.UserPermissions.ADA_API_STREAM_READ_WRITE_EU]: ["userManagement"],
	[ApiModelTypes.UserPermissions.ADA_API_POLICY_ASSIGNMENT_EU]: ["vehicleManagement"],
	[ApiModelTypes.UserPermissions.ADA_API_POLICY_FEATURE_WRITE_EU]: [
		"policyCreation",
		"featureNames"
	],
	[ApiModelTypes.UserPermissions.ADA_API_FEATURE_WRITE_EU]: ["vehicleManagement"],
	[ApiModelTypes.UserPermissions.ADA_API_WEB_ACCESS_EU]: ["home", "documentation"]
};
