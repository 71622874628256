import { ClickAwayListener } from "@mui/base";
import * as Icons from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";
import * as Material from "@mui/material";
import _ from "lodash";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";
import { $enum } from "ts-enum-util";
import * as ApiModelTypes from "../../api/apiModelTypes";
import {
	IS_DEV_ENVIRONMENT,
	IS_PRODUCTION_ENVIRONMENT,
	IS_STAGE_ENVIRONMENT,
	VIN_LENGTH
} from "../../constants";
import { useMediaQueries } from "../../hooks/useMediaQueries";
import { recordEvent } from "../../utils/analyticsUtil";
import { IsA51UserContext } from "../../utils/contextUtils/isA51UserContext";
import { Timezone } from "../../utils/contextUtils/timezonePreferenceContext";
import { adaConfirm } from "../../utils/dialogUtils";
import { PINNED_POLICIES, PINNED_VINS } from "../../utils/localStorage";
import { Regions } from "../../utils/regionUtils";
import { ACTIVE_DEVICE_TAB } from "../../utils/sessionStorage";
import { FavoritesContext } from "../../utils/userProfileUtils/favoritesContext";
import { removeFromFavoriteList } from "../../utils/userProfileUtils/favoritesUtils";
import { AdaSwitch } from "../AdaSwitch";
import { Time } from "../Time";
import a51Logo from "./a51.png";
import a51DarkLogo from "./a51Dark.png";
import * as Styled from "./leftNavStyles";
import { MENU_OPTIONS, MultiScene, SceneLabels, SingleScene } from "./sceneUtils";

interface RegionConfig {
	/**
	 * The region name.
	 */
	region: string;
	/**
	 * Called when the user changes regions.
	 * @param region - the region.
	 */
	onChangeRegion(region: string): void;
}

interface FavoriteConfig {
	label: string;
	recent: Array<string>;
	pinned: Array<string>;
	path: string;
}

enum FavoritesSections {
	PINNED = "pinned",
	RECENT = "recent"
}

interface Props {
	/**
	 * The currently logged in user.
	 */
	user: ApiModelTypes.Me;
	/**
	 * Region switching configuration functions.
	 */
	regionConfig: RegionConfig;
	/**
	 * Called when the user wishes to activate easter egg
	 */
	onEasterEgg(): void;
	/**
	 * Called when the user wishes to toggle the theme
	 */
	onToggleTheme(): void;
	/**
	 * Flag to toggle theme mode icon
	 */
	isDarkMode: boolean;
	/**
	 * Called whe the user wishes to logout.
	 */
	onLogout(): void;
	/**
	 * Called when user clicks permission change button, opens LandingPage
	 */
	onPermissionChangeRequest(): void;
	/**
	 * Called whe the user wishes to logout.
	 */
	onViewUserAccount(): void;
	/**
	 * Called when user toggles the preferred timezone
	 */
	onToggleTimezone(): void;
	/**
	 * The preferred timezone
	 */
	timezonePreference: Timezone | null;
}

const COMPONENT_NAME = "LeftNavigation";

/**
 * Core implementation of the {@link LeftNavigation}
 */
export const LeftNavigation: React.FC<Props> = (props) => {
	const { isScreenMedAndDown } = useMediaQueries();
	const [searchTerm, setSearchTerm] = React.useState<string>("");
	const [openMenuOption, setOpenMenuOption] = React.useState<MultiScene | null>(null);
	const [isProfileMenuOpen, setIsProfileMenuOpen] = React.useState<boolean>(false);
	const [isRegionMenuOpen, setIsRegionMenuOpen] = React.useState<boolean>(false);
	const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
	const [isSearchOpen, setIsSearchOpen] = React.useState<boolean>(false);
	const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
	const [isCapitalizationToggled, setIsCapitalizationToggled] = React.useState<boolean>(false);
	const [TimePreference, setTimePreference] = React.useState<"local" | "utc">("local");

	const IS_A51_USER = React.useContext(IsA51UserContext);

	const userFavorites = React.useContext(FavoritesContext);

	// The search input
	const searchInputRef = React.useRef<HTMLInputElement>(null);

	let timer: NodeJS.Timeout;

	const navigate = useNavigate();

	React.useEffect(() => {
		if (searchInputRef.current) {
			if (!isSearchOpen) {
				searchInputRef.current.blur(); // this closes autocomplete list when the search collapses
			}
		}
	}, [isSearchOpen]);

	/**
	 * Verifies that a user has adequate permissions to access a scene
	 * @param scene - the current scene to check
	 * @returns true if user can access the scene
	 */
	const isScenePermissable = (scene: MultiScene | SingleScene) => {
		if (scene.permissions && scene.permissions.length > 0) {
			return _.some(scene.permissions, (permission) => {
				return _.includes(props.user.operations, permission);
			});
		} else {
			return true;
		}
	};

	/**
	 * Listens to user's preference and switches the region
	 */
	window.addEventListener("changeRegion", (event: Event) => {
		const regionChangeEvent = event as CustomEvent;
		props.regionConfig.onChangeRegion(regionChangeEvent.detail.region);
	});

	/**
	 * Verifies that a user has permissions to follow a nav item's path or can view at least one subscene
	 * @param option - the current menu option to check
	 * @returns boolean if the option should be rendered
	 */
	const shouldRenderOption = (option: MultiScene | SingleScene) => {
		// immediately return false if option is not set to display
		if (!option.display) {
			return false;
		}

		if ("path" in option) {
			// check if user has permission to navigate to path
			if (option.permissions && option.permissions.length) {
				return isScenePermissable(option);
			} else {
				return true;
			}
		} else {
			// has an array of scenes, check if at least one scene is permissable
			return option.scenes.some((scene) => isScenePermissable(scene));
		}
	};

	/**
	 * Called when an item is hovered, if item has subscenes, starts a timer that ends with the nav expanding
	 * Else, closes the nav drawer
	 * Timer is used to optimize experience and keep drawer from opening new option when quickly mousing over
	 * @param item - the nav item being hovered
	 */
	const handleItemHover = (item: MultiScene | SingleScene) => {
		clearTimeout(timer); // cancel any previous scene's hover timer
		timer = setTimeout(() => {
			if ("scenes" in item) {
				setOpenMenuOption(item);
				setIsDrawerOpen(true);
			} else {
				handleDrawerClose();
			}
		}, 250); // prevents unwanted opening on a quick mouse over
	};

	/**
	 * Clears any current timers, open menu option, and closes drawer and search bar
	 */
	const handleDrawerClose = () => {
		setIsDrawerOpen(false);
		handleSearchClose();
		// Slight delay so that the main menu doesn't flash while closing
		setTimeout(() => setOpenMenuOption(null), 150);
		clearTimeout(timer);
	};

	/**
	 * Closes search bar and clears search term
	 */
	const handleSearchClose = () => {
		setIsSearchOpen(false);
		setSearchTerm("");
		searchInputRef?.current?.blur();
	};

	/**
	 * Handles routing when a nav item is selected
	 * Supports command + click to open in new tab
	 * @param event - the event resulting from click
	 * @param item - scene item clicked on
	 */
	const handleItemClick = (event: React.MouseEvent, item: MultiScene | SingleScene) => {
		if ("scenes" in item) {
			// Render sub menu
			setOpenMenuOption(item);
			setIsDrawerOpen(true);
		} else if ("path" in item) {
			const inNewTab =
				event.ctrlKey ||
				event.metaKey ||
				event.shiftKey ||
				("isExternal" in item && item.isExternal === true);

			handleNavigate(item.path, inNewTab);
		}
	};

	/**
	 * Handles clicking on a favorite from the nav by navigating to that favorite
	 * @param event the event resulting from the click
	 * @param path the path to the scene
	 * @param favoriteId the id of the favorite clicked
	 */
	const handleFavoriteClick = (event: React.MouseEvent, path: string, favoriteId: string) => {
		const toPath = `${path}/${favoriteId}`;
		const inNewTab = event.ctrlKey || event.metaKey || event.shiftKey;
		handleNavigate(toPath, inNewTab);
	};

	/**
	 * Trims search term and determines context (policies or vin). Handles search
	 */
	const handleSearch = (event: React.MouseEvent | React.KeyboardEvent): void => {
		const trimmedSearchTerm: string = _.trim(searchTerm);
		if (trimmedSearchTerm === "") {
			return;
		}

		/**
		 *  Check if the search term is a potential VIN
		 */
		const isVin = (term: string) =>
			term.length === VIN_LENGTH &&
			!term.includes(" ") &&
			!term.includes(",") &&
			!term.includes("_");

		let context;
		// Determine the context based on the search term
		if (isVin(trimmedSearchTerm)) {
			context = "vins";
			recordEvent("Search", "VIN");
		} else {
			context = "policies";
			recordEvent("Search", "Policy");
		}
		const path = `/${context}/${
			isCapitalizationToggled ? _.toUpper(trimmedSearchTerm) : trimmedSearchTerm
		}`;
		// Conditionally open in new tab or current window (navigate can't open in new window)
		const inNewTab = event.ctrlKey || event.metaKey || event.shiftKey;
		handleNavigate(path, inNewTab);

		// resets VIN profile tab to lastCheckedIn on new VIN search
		sessionStorage.removeItem(ACTIVE_DEVICE_TAB);
	};

	/**
	 * Navigates to a path and closes the drawer
	 * @param path the path to navigate to
	 * @param inNewTab a flag to open the path in a new tab
	 */
	const handleNavigate = (path: string, inNewTab = false) => {
		handleDrawerClose();
		if (inNewTab) {
			window.open(path, "_blank");
		} else {
			navigate(path);
		}
	};

	/**
	 * Toggles the timezone preference between "local" and "utc".
	 * @returns {void}
	 */
	const handleToggleTimezone = () => {
		setTimePreference((prevPreference) => (prevPreference === "utc" ? "local" : "utc"));
		props.onToggleTimezone();
	};

	// Hook to handle '/' key press to open the search bar
	useHotkeys("/", (event) => {
		event.preventDefault();
		setSearchTerm("");
		setIsSearchOpen(true);
		if (searchInputRef.current) {
			searchInputRef.current.focus();
		}
	});

	/**
	 * Closes popover menu
	 */
	const closePopoverMenu = () => {
		setIsProfileMenuOpen(false);
		setIsRegionMenuOpen(false);
		setAnchorElement(null);
	};

	/**
	 * Renders the correct logo for the nav
	 * Either A51 or ADA
	 * @returns A rendered logo element
	 */
	const renderNavLogo = () => {
		return IS_A51_USER ? (
			<img
				src={a51Logo}
				alt="Area 51"
				width={63}
				onClick={() => handleNavigate("/")}
				aria-label="home"
			></img>
		) : (
			<Styled.AdaLogoTypography onClick={() => handleNavigate("/")} aria-label="home">
				ada
			</Styled.AdaLogoTypography>
		);
	};
	/**
	 * Handles rendering a search bar inside a set height container
	 * @returns the rendered search bar
	 */
	const renderSearchBar = () => {
		return (
			<Styled.NavItem
				onMouseLeave={() => handleSearchClose()}
				searchItem={true}
				style={
					isScreenMedAndDown
						? {
								flexDirection: "column",
								position: "absolute",
								width: "fit-content", // 100% width doesn't allow click outside to close search bar
								zIndex: 1 // brings above app content and breadcrumbs
						  }
						: { flexDirection: "row" }
				}
			>
				<Material.IconButton
					sx={{
						zIndex: 1, // Brings icon button above search bar when search bar is expanded
						display: !isScreenMedAndDown || isSearchOpen ? "flex" : "none",
						marginLeft: isScreenMedAndDown ? "28px" : "0"
					}}
					onClick={(event) =>
						isSearchOpen ? handleSearch(event) : setIsSearchOpen(true)
					}
					disableRipple
					onMouseEnter={() => {
						setIsSearchOpen(true);
						searchInputRef.current?.focus();
					}}
				>
					<Icons.Search className="search-icon" fontSize={"large"} />
				</Material.IconButton>
				<Styled.SearchBar
					isA51User={IS_A51_USER}
					isOpen={isSearchOpen || (!isScreenMedAndDown && isDrawerOpen)}
				>
					<Material.Tooltip
						placement="top"
						title="ctrl+enter to open in new tab"
						disableFocusListener
						enterDelay={200}
					>
						<Styled.SearchAutocomplete
							value={searchTerm}
							aria-label="Search VIN or Policy"
							onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
								if (event.key === "Enter") {
									handleSearch(event);
								}
							}}
							onInputChange={(event, value) => {
								setSearchTerm(value);
							}}
							options={[...userFavorites.recentVins, ...userFavorites.recentPolicies]}
							fullWidth
							forcePopupIcon={false}
							freeSolo
							disableClearable
							renderInput={(params) => {
								const { InputLabelProps, InputProps, ...otherParams } = params; // Have to separate out InputLabelProps, doesn't exist on InputBase
								return (
									<Material.InputBase
										{...InputProps}
										{...otherParams}
										placeholder="Search VIN or Policy"
										inputRef={searchInputRef}
										sx={{
											margin: "0 auto 0 55px",
											"& input": {
												textTransform:
													// check for empty string here to avoid transforming placeholder
													isCapitalizationToggled &&
													_.trim(searchTerm) !== ""
														? "uppercase"
														: "none"
											}
										}}
										endAdornment={
											<AdaSwitch
												onAriaLabel="toggle search uppercase off"
												offAriaLabel="toggle search to uppercase"
												icon={Icons.KeyboardCapslock}
												isSwitchChecked={isCapitalizationToggled}
												onChange={() => {
													setIsCapitalizationToggled(
														!isCapitalizationToggled
													);
													if (!isCapitalizationToggled) {
														recordEvent(
															"Capitalization Toggle",
															"@LeftNav"
														);
													}
													searchInputRef.current?.focus();
												}}
												isSmall={true}
											></AdaSwitch>
										}
									></Material.InputBase>
								);
							}}
						></Styled.SearchAutocomplete>
					</Material.Tooltip>
				</Styled.SearchBar>
			</Styled.NavItem>
		);
	};

	/**
	 * Handles rendering a button to toggle the profile menu
	 * @returns the rendered profile button and its menu
	 */
	const renderProfileButton = () => {
		return (
			<div>
				<Styled.PopoverMenu
					open={!!isProfileMenuOpen}
					onClick={props.onEasterEgg}
					onClose={closePopoverMenu}
					anchorEl={anchorElement}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
					transformOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					disableScrollLock // if this is not true, the menu adds padding to the page for some reason
				>
					<Material.MenuItem
						onClick={() => {
							closePopoverMenu();
							props.onViewUserAccount();
						}}
					>
						<Material.ListItemIcon>
							<Styled.Avatar
								isA51User={IS_A51_USER}
								variant={"rounded"}
								src={
									IS_A51_USER
										? props.isDarkMode
											? a51Logo
											: a51DarkLogo
										: undefined
								}
							/>
						</Material.ListItemIcon>
						<Material.Typography>
							<strong>
								{props.user.given_name && props.user.family_name
									? `${props.user.given_name} ${props.user.family_name}`
									: props.user.cognito_username
									? props.user.cognito_username.replaceAll("_", " ")
									: " Guest User"}
							</strong>
						</Material.Typography>
					</Material.MenuItem>
					<Material.Tooltip
						placement="right"
						title={`Toggle to display time in ${
							props.timezonePreference === "utc"
								? Intl.DateTimeFormat().resolvedOptions().timeZone
								: "UTC"
						} timezone`}
					>
						<Material.MenuItem>
							<Time
								timezonePreference={TimePreference}
								onToggleTime={handleToggleTimezone}
							/>
						</Material.MenuItem>
					</Material.Tooltip>
					<Material.MenuItem
						onClick={() => {
							closePopoverMenu();
							props.onToggleTheme();
						}}
						aria-label={
							props.isDarkMode ? "change to light theme" : "change to dark theme"
						}
					>
						<Material.ListItemIcon>
							{props.isDarkMode ? <Icons.WbSunny /> : <Icons.Brightness2 />}
						</Material.ListItemIcon>
						{props.isDarkMode ? (
							<Material.Typography>Light Theme </Material.Typography>
						) : (
							<Material.Typography>Dark Theme </Material.Typography>
						)}
					</Material.MenuItem>
					<Material.Divider></Material.Divider>
					<Material.MenuItem
						onClick={() => {
							closePopoverMenu();
							props.onLogout();
						}}
						aria-label="logout"
					>
						Logout
					</Material.MenuItem>
				</Styled.PopoverMenu>
				<Material.ButtonBase
					aria-label="profile and preferences"
					sx={{ width: "100%" }}
					onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
						setAnchorElement(event.currentTarget);
						setIsProfileMenuOpen(!isProfileMenuOpen);
					}}
				>
					<Icons.Person className="nav-icon" fontSize="large" />
				</Material.ButtonBase>
				{!isScreenMedAndDown && <p className="scene-caption">Profile & Preferences</p>}
			</div>
		);
	};

	/**
	 * Handles rendering a button to toggle region select menu
	 * @returns the rendered region button and its menu
	 */
	const renderRegionButton = () => {
		return (
			<div>
				<Styled.PopoverMenu
					open={!!isRegionMenuOpen}
					onClose={closePopoverMenu}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
					transformOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					anchorEl={anchorElement}
					anchorPosition={{ left: 0, top: 0 }}
					disableScrollLock
				>
					{$enum(Regions).map((region, index) => {
						return (
							<Material.MenuItem
								value={region}
								key={index}
								onClick={() => {
									adaConfirm({
										title: "Warning",
										body: "You may not have the same permissions in the region you are about to switch to. \
										If you have any unexpected gaps in your permissions please contact the website \
										administrators for access.",
										confirmText: "Switch",
										onConfirm: () => {
											closePopoverMenu();
											props.regionConfig.onChangeRegion(region.valueOf());
										}
									});
								}}
								selected={props.regionConfig.region === region}
								aria-label={region}
							>
								{region}
							</Material.MenuItem>
						);
					})}
				</Styled.PopoverMenu>
				<Material.ButtonBase
					aria-label="change region"
					sx={{ width: "100%" }}
					onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
						setAnchorElement(event.currentTarget);
						setIsRegionMenuOpen(!isRegionMenuOpen);
					}}
				>
					<Styled.AdaLogoTypography
						isA51User={IS_A51_USER}
						className="region nav-icon"
						aria-label="change region"
					>
						{props.regionConfig.region.toLowerCase()}
					</Styled.AdaLogoTypography>
				</Material.ButtonBase>
				{!isScreenMedAndDown && (
					<p style={{ marginBottom: "1.125rem" }} className="scene-caption">
						Change Region
					</p>
				)}
			</div>
		);
	};

	/**
	 * Handles rendering the left navigation if medium or larger screen
	 * @returns the rendered navigation bar
	 */
	const renderDesktopNav = () => {
		return (
			<Styled.LeftBar>
				<Styled.HeaderWrapper>
					<Styled.NavSection sx={{ width: Styled.collapsedNavWidth + "rem" }}>
						{!IS_PRODUCTION_ENVIRONMENT && (
							<Styled.EnvFlag isA51User={IS_A51_USER}>
								<Styled.EnvLabel>
									{IS_DEV_ENVIRONMENT ? "dev" : "stage"}
								</Styled.EnvLabel>
							</Styled.EnvFlag>
						)}
						<Styled.NavItem>{renderNavLogo()}</Styled.NavItem>
						{renderSearchBar()}
					</Styled.NavSection>
					<Styled.NavDivider></Styled.NavDivider>
				</Styled.HeaderWrapper>
				<Styled.NavSection style={{ flexGrow: 1, overflowY: "auto" }}>
					<Styled.NavSection>
						{MENU_OPTIONS.filter((option) => shouldRenderOption(option)).map(
							(option, index) => {
								return (
									<Styled.NavItem
										key={index}
										onMouseEnter={() => handleItemHover(option)}
										onClick={(event) => handleItemClick(event, option)}
										aria-label="option.label"
										className={
											option.label === openMenuOption?.label
												? "highlighted"
												: ""
										}
									>
										{option.icon && (
											<option.icon
												className="nav-icon"
												fontSize="large"
											></option.icon>
										)}
										<p className="scene-caption">{option.label}</p>
									</Styled.NavItem>
								);
							}
						)}
					</Styled.NavSection>
					<Styled.NavSection
						onMouseEnter={() => handleDrawerClose()}
						sx={{
							marginBottom: ".75rem",
							flexGrow: 1,
							justifyContent: "flex-end",
							display: "flex"
						}}
					>
						<Styled.NavItem>{renderRegionButton()}</Styled.NavItem>
						<Styled.NavItem>{renderProfileButton()}</Styled.NavItem>
					</Styled.NavSection>
				</Styled.NavSection>
			</Styled.LeftBar>
		);
	};

	/**
	 * Handles rendering the mobile bottom navigation
	 * @returns the rendered mobile navigation bar
	 */
	const renderMobileNav = () => {
		return (
			<Styled.BottomBar>
				<Material.IconButton
					onClick={() => {
						if (isDrawerOpen) {
							handleDrawerClose();
						} else {
							setIsDrawerOpen(true);
							handleSearchClose();
						}
					}}
					aria-label="open menu"
				>
					<Icons.Menu></Icons.Menu>
				</Material.IconButton>
				<Material.IconButton
					onClick={(event) => {
						if (isSearchOpen) {
							handleSearch(event);
						} else {
							searchInputRef.current?.focus();
							setIsDrawerOpen(false);
							setIsSearchOpen(true);
						}
					}}
					aria-label={isSearchOpen ? "search" : "open search"}
				>
					<Icons.Search />
				</Material.IconButton>
				{renderRegionButton()}
				{renderProfileButton()}
			</Styled.BottomBar>
		);
	};

	/**
	 * Handles rendering a slimmed down mobile drawer
	 * @returns the rendered drawer
	 */
	const renderMobileDrawer = () => {
		return (
			<>
				{/* Env and logo section, not full width */}
				<Styled.HeaderWrapper>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<Styled.NavSection style={{ width: Styled.collapsedNavWidth + "rem" }}>
							{!IS_PRODUCTION_ENVIRONMENT && (
								<Styled.EnvFlag isA51User={IS_A51_USER}>
									<Styled.EnvLabel>
										{IS_DEV_ENVIRONMENT ? "dev" : "stage"}
									</Styled.EnvLabel>
								</Styled.EnvFlag>
							)}
							<Styled.NavItem>{renderNavLogo()}</Styled.NavItem>
						</Styled.NavSection>
						<Material.IconButton
							onClick={() => {
								if (openMenuOption === null) {
									handleDrawerClose();
								} else {
									setOpenMenuOption(null);
								}
							}}
						>
							<Icons.ChevronLeft sx={{ fontSize: Styled.mobileIconSize + "rem" }} />
						</Material.IconButton>
					</div>
					<Styled.NavDivider></Styled.NavDivider>
				</Styled.HeaderWrapper>
				<Styled.NavSection style={{ overflowY: "auto" }}>
					{openMenuOption === null &&
						MENU_OPTIONS.filter((option) => shouldRenderOption(option)).map(
							(option, index) => {
								return (
									<Styled.MenuOptionLabel
										key={index}
										onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
											handleItemClick(event, option)
										}
									>
										{option.label}
									</Styled.MenuOptionLabel>
								);
							}
						)}
					{openMenuOption !== null &&
						"scenes" in openMenuOption &&
						openMenuOption.scenes
							.filter((option) => shouldRenderOption(option))
							.map((option, index) => {
								return (
									<Styled.MenuOptionLabel
										key={index}
										onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
											handleItemClick(event, option)
										}
									>
										{option.label}
									</Styled.MenuOptionLabel>
								);
							})}

					{openMenuOption?.label === SceneLabels.VEHICLES ||
					openMenuOption?.label === SceneLabels.POLICIES ? (
						<>
							{renderFavorites(
								FavoritesSections.PINNED,
								openMenuOption?.label as SceneLabels.POLICIES | SceneLabels.VEHICLES
							)}

							{renderFavorites(
								FavoritesSections.RECENT,
								openMenuOption?.label as SceneLabels.POLICIES | SceneLabels.VEHICLES
							)}
						</>
					) : (
						false
					)}
				</Styled.NavSection>
			</>
		);
	};

	/**
	 * Dynamically renders recent or pinned favorite accordions
	 * @param type the context (pinned or recent)
	 * @param sceneLabel the label of the currently open scene (Vehicles or Policies)
	 * @returns a rendered accordion component
	 */
	const renderFavorites = (
		type: FavoritesSections,
		sceneLabel: SceneLabels.VEHICLES | SceneLabels.POLICIES
	): React.JSX.Element => {
		/**
		 * Configs to dynamically generate identical accordions
		 */
		const favoriteConfigs: Record<SceneLabels.VEHICLES | SceneLabels.POLICIES, FavoriteConfig> =
			{
				[SceneLabels.VEHICLES]: {
					label: "VINs",
					recent: userFavorites.recentVins,
					pinned: userFavorites.pinnedVins,
					path: "/vins"
				},
				[SceneLabels.POLICIES]: {
					label: "Policies",
					recent: userFavorites.recentPolicies,
					pinned: userFavorites.pinnedPolicies,
					path: "/policies"
				}
			};

		const favoritesArray = favoriteConfigs[sceneLabel][type];

		return favoritesArray.length > 0 ? (
			<Styled.FavoritesAccordion
				disableGutters
				elevation={0}
				sx={{
					"&:before": {
						display: "none" // Collapsed MUI Accordion has a 1px wide line at the top, this removes it
					}
				}}
			>
				<Material.AccordionSummary sx={{ margin: 0 }} expandIcon={<ExpandMore />}>
					<Styled.MenuOptionWrapper isAccordion={true}>
						<Styled.MenuOptionLabel>
							{`${_.capitalize(type)} ${favoriteConfigs[sceneLabel].label}`}
						</Styled.MenuOptionLabel>
					</Styled.MenuOptionWrapper>
				</Material.AccordionSummary>
				<Material.AccordionDetails sx={{ padding: 0, overflowY: "auto" }}>
					{favoritesArray.map((favorite, index) => {
						return (
							<div
								style={{
									display: "flex",
									justifyContent: "space-between"
								}}
								key={favorite + index}
							>
								<Styled.MenuOptionWrapper
									onClick={(event) =>
										handleFavoriteClick(
											event,
											favoriteConfigs[sceneLabel].path,
											favorite
										)
									}
								>
									<Styled.MenuOptionLabel
										fontSize={"initial"}
										sx={{
											textWrap: "wrap",
											wordBreak: "break-all",
											marginLeft: "1rem"
										}}
									>
										{favorite}
									</Styled.MenuOptionLabel>
								</Styled.MenuOptionWrapper>
								{type === "pinned" && (
									<Material.IconButton
										onClick={() => {
											userFavorites.setUserFavorites({
												...userFavorites,
												[sceneLabel === SceneLabels.VEHICLES
													? "pinnedVins"
													: "pinnedPolicies"]: removeFromFavoriteList(
													favoritesArray,
													sceneLabel === SceneLabels.VEHICLES
														? PINNED_VINS
														: PINNED_POLICIES,
													favorite
												)
											});
										}}
									>
										<Icons.PushPin />
									</Material.IconButton>
								)}
							</div>
						);
					})}
				</Material.AccordionDetails>
			</Styled.FavoritesAccordion>
		) : (
			<Styled.MenuOptionWrapper isAccordion={true}>
				<Styled.MenuOptionLabel>{`No ${type} ${favoriteConfigs[sceneLabel].label}`}</Styled.MenuOptionLabel>
			</Styled.MenuOptionWrapper>
		);
	};

	return (
		<ClickAwayListener
			onClickAway={() => {
				handleDrawerClose();
			}}
		>
			<Styled.LeftNavigationWrapper
				isA51User={IS_A51_USER}
				onMouseLeave={() => handleDrawerClose()}
			>
				{/* The search bar for mobile screens */}
				{isScreenMedAndDown && renderSearchBar()}
				{/* The vertical or horizontal bar */}
				<Styled.AppBarWrapper isMdAndDown={isScreenMedAndDown}>
					{isScreenMedAndDown ? renderMobileNav() : renderDesktopNav()}
				</Styled.AppBarWrapper>
				{/* Conditionally open drawer */}
				<Styled.LeftDrawer
					open={isDrawerOpen}
					variant="persistent"
					onMouseEnter={() => clearTimeout(timer)}
					isMdAndDown={isScreenMedAndDown}
				>
					{/* The smaller mobile drawer */}
					{isScreenMedAndDown ? (
						renderMobileDrawer()
					) : (
						<>
							<Styled.HeaderWrapper>
								<Styled.NavItem>
									<Styled.MenuOptionLabel
										onClick={() => handleNavigate("/")}
										className="env-label"
										variant="h6"
										sx={{ marginBottom: "0" }}
									>
										{IS_DEV_ENVIRONMENT
											? "Dev - Integration"
											: IS_STAGE_ENVIRONMENT
											? "Stage - Preprod"
											: ""}
									</Styled.MenuOptionLabel>
								</Styled.NavItem>
								{/* Empty item for search to expand over */}
								<Styled.NavItem></Styled.NavItem>
								<Styled.NavDivider></Styled.NavDivider>
							</Styled.HeaderWrapper>
							<Styled.NavSection
								className="subscene-list"
								isOpen={isDrawerOpen}
								sx={{ fontSize: "20px" }}
							>
								{openMenuOption !== null &&
									"scenes" in openMenuOption &&
									openMenuOption.scenes
										.filter((scene) => {
											// only render scenes that are set to display
											if (scene.display) {
												return isScenePermissable(scene);
											}
										})
										.map((scene, index) => {
											return (
												<Styled.MenuOptionWrapper
													key={index}
													onClick={(event) =>
														handleItemClick(event, scene)
													}
												>
													<Styled.MenuOptionLabel variant="h6">
														{scene.label}
													</Styled.MenuOptionLabel>
												</Styled.MenuOptionWrapper>
											);
										})}
								{openMenuOption?.label === SceneLabels.VEHICLES ||
								openMenuOption?.label === SceneLabels.POLICIES ? (
									<>
										{renderFavorites(
											FavoritesSections.PINNED,
											openMenuOption?.label as
												| SceneLabels.POLICIES
												| SceneLabels.VEHICLES
										)}

										{renderFavorites(
											FavoritesSections.RECENT,
											openMenuOption?.label as
												| SceneLabels.POLICIES
												| SceneLabels.VEHICLES
										)}
									</>
								) : (
									false
								)}
							</Styled.NavSection>
						</>
					)}
				</Styled.LeftDrawer>
			</Styled.LeftNavigationWrapper>
		</ClickAwayListener>
	);
};
LeftNavigation.displayName = COMPONENT_NAME;
