import * as Icons from "@mui/icons-material";
import * as Material from "@mui/material";
import { SnackbarOrigin } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { NotificationEvent } from "../../components/App";
import InfoPanel from "../../components/InfoPanel";
import { Notification } from "../../components/Notification";
import { IS_DEV_ENVIRONMENT, IS_STAGE_ENVIRONMENT } from "../../constants";
import { renderCustomHead } from "../../renderCustomHead";
import { IsA51UserContext } from "../../utils/contextUtils/isA51UserContext";
import { SHOW_INTRO_MESSAGE, SHOW_SURVEY_POPUP } from "../../utils/localStorage";
import ada from "./ada.png";
import * as HomeStyles from "./homeStyles";
import releaseNotes from "./release-notes.json";

/**
 * Props interface for this component.
 */
interface Props {
	/**
	 * Called when a notification is to be displayed.
	 * @param notificationEvent - the notification event payload.
	 */
	onDisplayNotification(notificationEvent: NotificationEvent): void;
	/**
	 * Helps to hide intro message, when user clicks close.
	 */
	onHideIntroMessage(): void;
	/**
	 * Boolean flag to determine if the InfoIcon in the InfoPanel is highlighted.
	 * @default false
	 */
	isInfoIconHighlighted: boolean;
	/**
	 * Function to set the highlight state of the InfoIcon.
	 * @param highlight - Boolean value indicating whether the InfoIcon should be highlighted.
	 */
	setIsInfoIconHighlighted: (highlight: boolean) => void;
}

/**
 * Name of this component.
 */
const COMPONENT_NAME = "Home";

/**
 * Core implementation of {@link Home}
 * @param props - component props.
 */
const HomePreMemo: React.FC<Props> = (props) => {
	const [surveyPopupState, setsurveyPopupState] = React.useState({
		variant: "info",
		message: "",
		snackbarKey: 0,
		isOpen: false,
		actionButton: null as React.ReactNode,
		onClose: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			if (event) {
				event.stopPropagation(); // Prevent the event from bubbling up and closing the popup
			}
			setsurveyPopupState((prevProps) => ({ ...prevProps, isOpen: true }));
		},
		anchorOrigin: { vertical: "top", horizontal: "center" } as SnackbarOrigin,
		style: {},
		isMultiline: false
	});

	/**
	 * @inheritdoc
	 */
	useEffect(() => {
		displayGreeting();
		displaySurveyPopup();
	}, []);

	const IS_A51_USER = React.useContext(IsA51UserContext);

	/**
	 * Called to display the greeting message to the user.
	 * The greeting will not appear if the user has previously
	 * closed the message (this state being set in local storage).
	 *
	 * Will also display a toast to the user if their browser instance
	 * does not have WebGl enabled
	 */
	const displayGreeting = () => {
		if (localStorage.getItem(SHOW_INTRO_MESSAGE) === "yes") {
			let messageToDisplay =
				"Welcome to the ADA Production site. To access the integration or pre-prod portal, please visit: ";
			let urlToRedirect = "https://dev.alldataapp.com or https://stg.alldataapp.com";

			if (IS_STAGE_ENVIRONMENT) {
				messageToDisplay =
					"Welcome to the Ada Preprod portal. You are on the pre-production environment. To access the production site, please visit";
				urlToRedirect = "https://alldataapp.com";
			}

			if (IS_DEV_ENVIRONMENT) {
				messageToDisplay =
					"Welcome to the Ada Integration portal. You are on the integration environment. To access the production site, please visit";
				urlToRedirect = "https://alldataapp.com";
			}

			props.onDisplayNotification({
				message: `${messageToDisplay} ${urlToRedirect}${
					IS_A51_USER
						? ". \n\nNote - You are an Area 51 user and have limited viewing access. For more information see Confluence documentation"
						: ""
				}`,
				variant: "info",
				customAction: (
					<Material.IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
							event.stopPropagation();
							props.onHideIntroMessage();
						}}
					>
						<Icons.Close fontSize="small" />
					</Material.IconButton>
				),
				isMultiline: IS_A51_USER
			});
		}
	};

	if (localStorage.getItem(SHOW_SURVEY_POPUP) === null) {
		localStorage.setItem(SHOW_SURVEY_POPUP, "true");
	}

	/**
	 * Displays the survey popup notification
	 */
	const displaySurveyPopup = () => {
		if (localStorage.getItem(SHOW_SURVEY_POPUP) !== "false") {
			setsurveyPopupState({
				variant: "info",
				message: "We'd love to hear your feedback!",
				snackbarKey: new Date().getTime(),
				isOpen: true,
				isMultiline: false,
				actionButton: (
					<>
						<Material.Button
							size="small"
							color="inherit"
							variant="outlined"
							onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								event.stopPropagation();
								window.open("https://forms.office.com/e/QqZSn61Qfp", "_blank");
							}}
						>
							Take Survey
						</Material.Button>
						<Material.Tooltip title="Don’t show again" arrow>
							<Material.IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={(
									event: React.MouseEvent<HTMLButtonElement, MouseEvent>
								) => {
									event.stopPropagation();
									localStorage.setItem(SHOW_SURVEY_POPUP, "false");
									setsurveyPopupState((prevState) => ({
										...prevState,
										isOpen: false
									}));

									props.setIsInfoIconHighlighted(true);

									// Show a follow-up alert or popup
									setTimeout(() => {
										setsurveyPopupState({
											variant: "info",
											message:
												"Remember, you can still take the survey from the InfoPanel",
											snackbarKey: new Date().getTime(),
											isOpen: true,
											isMultiline: false,
											actionButton: (
												<>
													<Material.Button
														size="small"
														color="inherit"
														variant="outlined"
														onClick={() => {
															setsurveyPopupState((prevState) => ({
																...prevState,
																isOpen: false
															}));
															props.setIsInfoIconHighlighted(false);
														}}
													>
														OK
													</Material.Button>
												</>
											),
											onClose: () => {
												setsurveyPopupState((prevState) => ({
													...prevState,
													isOpen: false
												}));
												props.setIsInfoIconHighlighted(false);
											},
											anchorOrigin: {
												vertical: "top",
												horizontal: "right"
											},
											style: {
												margin: "0 20px 60px 0"
											}
										});
									}, 500); // Delay the follow-up message slightly
								}}
							>
								<Icons.Close fontSize="small" />
							</Material.IconButton>
						</Material.Tooltip>
					</>
				),
				onClose: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
					if (event) {
						event.stopPropagation();
					}
					setsurveyPopupState((prevState) => ({
						...prevState,
						isOpen: false
					}));
				},
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "right"
				},
				style: {
					margin: "0 20px 60px 0"
				}
			});
		}
	};

	/**
	 * Recursively renders the git logs
	 */
	const renderReleaseNotes = () => {
		const latestRelease = _.head(releaseNotes);
		const olderReleases = _.tail(releaseNotes);
		return (
			<div className={`${COMPONENT_NAME}__release-notes`}>
				<HomeStyles.CurrentRelease>
					<Material.Typography variant={"h4"} gutterBottom={true}>
						Latest Release (v{latestRelease!.version})
					</Material.Typography>

					<div>
						{!_.isEmpty(latestRelease!.features) && (
							<>
								<Material.Typography variant={"h6"}>
									What's New:
								</Material.Typography>
								<Material.List dense={true}>
									{_.map(latestRelease!.features, (feature) => {
										return (
											<Material.ListItem key={feature}>
												<Material.ListItemText primary={feature} />
											</Material.ListItem>
										);
									})}
								</Material.List>
							</>
						)}

						{!_.isEmpty(latestRelease!.bugfixes) && (
							<>
								<Material.Typography variant={"h6"}>Bugfixes:</Material.Typography>
								<Material.List dense={true}>
									{_.map(latestRelease!.bugfixes, (bugfix) => {
										return (
											<Material.ListItem key={bugfix}>
												<Material.ListItemText primary={bugfix} />
											</Material.ListItem>
										);
									})}
								</Material.List>
							</>
						)}
						{!_.isEmpty(latestRelease!.other) && (
							<>
								<Material.Typography variant={"h6"}>Other:</Material.Typography>
								<Material.List dense={true}>
									{_.map(latestRelease!.other, (other) => {
										return (
											<Material.ListItem key={other}>
												<Material.ListItemText primary={other} />
											</Material.ListItem>
										);
									})}
								</Material.List>
							</>
						)}
					</div>
				</HomeStyles.CurrentRelease>
				<Material.Accordion>
					<Material.AccordionSummary
						expandIcon={<Icons.ExpandMore />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Material.Typography>Older Releases</Material.Typography>
					</Material.AccordionSummary>
					<Material.AccordionDetails>
						{_.map(olderReleases, (releaseNote) => {
							return (
								<div key={releaseNote.version}>
									<Material.Typography variant={"h4"} gutterBottom={true}>
										v{releaseNote.version}
									</Material.Typography>
									<div>
										{!_.isEmpty(releaseNote.features) && (
											<>
												<Material.Typography variant={"h6"}>
													What's New:
												</Material.Typography>
												<Material.List dense={true}>
													{_.map(releaseNote.features, (feature) => {
														return (
															<Material.ListItem key={feature}>
																<Material.ListItemText
																	primary={feature}
																/>
															</Material.ListItem>
														);
													})}
												</Material.List>
											</>
										)}

										{!_.isEmpty(releaseNote.bugfixes) && (
											<>
												<Material.Typography variant={"h6"}>
													Bugfixes:
												</Material.Typography>
												<Material.List dense={true}>
													{_.map(releaseNote.bugfixes, (bugfix) => {
														return (
															<Material.ListItem key={bugfix}>
																<Material.ListItemText
																	primary={bugfix}
																/>
															</Material.ListItem>
														);
													})}
												</Material.List>
											</>
										)}
									</div>
								</div>
							);
						})}
					</Material.AccordionDetails>
				</Material.Accordion>
			</div>
		);
	};

	return (
		<Material.Fade in={true}>
			<HomeStyles.SceneContent>
				{renderCustomHead("Home")}
				
				<Notification
					variant="info"
					message={surveyPopupState.message}
					snackbarKey={surveyPopupState.snackbarKey}
					isOpen={surveyPopupState.isOpen}
					actionButton={surveyPopupState.actionButton}
					onClose={surveyPopupState.onClose}
					anchorOrigin={surveyPopupState.anchorOrigin}
					isMultiline={surveyPopupState.isMultiline}
					style={{ margin: "0 20px 60px 0" }}
				/>

				<HomeStyles.BannerGrid container={true} spacing={2}>
					<Material.Grid item={true} sm={3}>
						<img src={ada} alt={"Ada Lovelace"} width={"100%"} />
					</Material.Grid>
					<Material.Grid item={true} sm={9}>
						<HomeStyles.IpaText variant={"h3"} gutterBottom={true}>
							Ada Lovelace /ˈeɪdə lʌvleɪs/
						</HomeStyles.IpaText>
						<Material.Typography paragraph={true}>
							Ada Lovelace was the first to recognize that the machine had
							applications beyond pure calculation, and published the first algorithm
							intended to be carried out by such a machine.
						</Material.Typography>
						<Material.Typography paragraph={true}>
							Ada Lovelace is sometimes regarded as the first to recognize the full
							potential of a "computing machine" and one of the first computer
							programmers.
						</Material.Typography>
						<Material.Typography paragraph={true}>
							In following in her footsteps, the All Data App ventures to recognize
							the full potential of our vehicles by exposing the finest details of the
							vehicle and its signals to allow all of us to enhance its capabilities
							for improved performance, safety, and reliability.
						</Material.Typography>
					</Material.Grid>
				</HomeStyles.BannerGrid>
				<Material.Container>{renderReleaseNotes()}</Material.Container>
			</HomeStyles.SceneContent>
		</Material.Fade>
	);
};
export const Home = React.memo(HomePreMemo);
Home.displayName = COMPONENT_NAME;
