import _ from "lodash";

/**
 * Available environments for this app
 */
export enum AppEnvironment {
	DEV = "dev",
	STAGE = "stage",
	PROD = "prod"
}

/**
 * If the current environment is the production environment.
 */
export const IS_PRODUCTION_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === AppEnvironment.PROD;

/**
 * If the current environment is the stage environment.
 */
export const IS_STAGE_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === AppEnvironment.STAGE;

/**
 * If the current environment is the dev environment.
 */
export const IS_DEV_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === AppEnvironment.DEV;

/**
 * If the current environment is the local dev environment
 */
export const IS_LOCAL_DEV_ENVIRONMENT =
	process.env.REACT_APP_IS_LOCAL_DEV === "true" ? true : false;
/**
 * If the user agent's OS is Windows
 */
export const IS_WINDOWS_OS = window.navigator.userAgent.match(/Windows/i);

/**
 * If the user agent's OS is MacOS
 */
export const IS_MAC_OS = window.navigator.userAgent.match(/Mac/i);

/**
 * If the user agent's OS is Linux
 */
export const IS_LINUX_OS = window.navigator.userAgent.match(/Linux/i);

/**
 * If the user agent's OS is Android
 */
export const IS_ANDROID_OS = window.navigator.userAgent.match(/Android/i);

/**
 * If the user agent's OS is iOS
 */
export const IS_IOS_OS = window.navigator.userAgent.match(/iOS/i);

/**
 * The prefix for Area 51 Groups
 */
export const AREA_51_PREFIX = "source-";

/**
 * Tests group for Area 51 prefix
 * @param group the group to test
 * @returns boolean if the group is an Area 51 group
 */
export const IS_AREA_51_GROUP = (group: string) => new RegExp("^source(_|-)", "i").test(group);

/**
 * Default group ids associated with each app environment
 * These numbers are arbitrary currently
 */
export enum DefaultPolicyGroupId {
	DEV = 91,
	STAGE = 95,
	PROD = 99
}

/**
 * Default renew value for policies
 * Unit is milliseconds
 */
export const DefaultPolicyRenew = 600000;

/**
 * A mapping of known operator abbreviations to
 * their appropriate symbol, if any.
 */
export const OPERATOR_SYMBOL_MAP = {
	ADD: "+",
	SUB: "-",
	MUL: "*",
	NEG: "NEG",
	DIV: "/",
	MOD: "%",
	LT: "<",
	LTE: "<=>",
	EQ: "=",
	GTE: ">=",
	GT: ">",
	NEQ: "!=",
	AND: "AND",
	OR: "OR",
	NOT: "NOT",
	LSHIFT: "<<",
	RSHIFT: ">>",
	ROUND: "ROUND",
	CEIL: "CEIL",
	FLOOR: "FLOOR",
	MAX: "MAX",
	MIN: "MIN",
	ABS: "ABS",
	CBRT: "CBRT",
	EXP: "EXP",
	LOG: "LOG",
	LOG10: "LOG10",
	POW: "POW",
	RANDOM: "RANDOM",
	SQRT: "SQRT",
	BITAND: "BITAND",
	BITOR: "BITOR",
	BITNOT: "BITNOT",
	BITXOR: "BITXOR",
	BYTE_AT: "BYTE_AT",
	XOR: "XOR",
	//String operations
	STREQ: "STREQ",
	STREQIC: "STREQIC",
	STRNEQ: "STRNEQ",
	STRNEQIC: "STRNEQIC",
	STRCOMPARE: "STRCOMPARE",
	STRCOMPAREIC: "STRCOMPAREIC",
	STRSUBSTRING: "STRSUBSTRING",
	STRCONCAT: "STRCONCAT",
	STRLENGTH: "STRLENGTH",
	STRSTARTSWITH: "STRSTARTSWITH",
	STRSTARTSWITHIC: "STRSTARTSWITHIC",
	STRENDSWITH: "STRENDSWITH",
	STRENDSWITHIC: "STRENDSWITHIC",
	STRCONTAINS: "STRCONTAINS",
	STRCONTAINSIC: "STRCONTAINSIC",
	STRGT: "STRGT",
	STRGTIC: "STRGTIC",
	STRLT: "STRLT",
	STRLTIC: "STRLTIC",
	STRGTEQ: "STRGTEQ",
	STRGTEQIC: "STRGTEQIC",
	STRLTEQ: "STRLTEQ",
	STRLTEQIC: "STRLTEQIC"
};

/**
 * The bus identifier type.
 */
export type BUS_IDENTIFIER = "CAN_C" | "CAN_I";

/**
 * The CAN channel identifier.
 */
export interface ChannelCode {
	/**
	 * The bus id
	 */
	bus: BUS_IDENTIFIER;
	/**
	 * Req
	 */
	req: string;
	/**
	 * Res
	 */
	res: string;
}

/**
 * Available CAN channels.
 */
export const CAN_CHANNELS = ["CAN_C", "CAN_I"];

export const INTERNAL_SOURCE_VALUES = [
	"Uptime",
	"Version",
	"HeapSize",
	"HeapMaxSize",
	"HeapFreeSize",
	"AvailableProcessors",
	"DeviceName",
	"CurrentPolicy",
	"ConnectionStatus",
	"FirmwareVersion",
	"Renew",
	"Sys-exit",
	"DeviceId"
];

/**
 * Mapping of bus values to diagnostic code.
 */
export const DIAGNOSTICS_BUS_MAP: Record<string, ChannelCode> = {
	ABS_PN: { req: "747", res: "4C7", bus: "CAN_C" },
	AFLS_PN: { req: "768", res: "4E8", bus: "CAN_C" },
	ANC_PN: { req: "7A5", res: "525", bus: "CAN_I" },
	ASCM_PN: { req: "766", res: "4E6", bus: "CAN_C" },
	ATMM: { req: "776", res: "4F6", bus: "CAN_C" },
	BCM_PN: { req: "620", res: "504", bus: "CAN_C" },
	BPCM_PN: { req: "7E7", res: "7EF", bus: "CAN_C" },
	CRSM: { req: "796", res: "516", bus: "CAN_I" },
	CSWM_PN: { req: "792", res: "512", bus: "CAN_I" },
	CVPM_PN: { req: "794", res: "514", bus: "CAN_I" },
	DASM: { req: "753", res: "4D3", bus: "CAN_C" },
	DDM_PN: { req: "784", res: "504", bus: "CAN_I" },
	DTCM_PN: { req: "74B", res: "4CB", bus: "CAN_C" },
	EPS_PN: { req: "75A", res: "4DA", bus: "CAN_C" },
	HCP_PN: { req: "7E2", res: "7EA", bus: "CAN_C" },
	HVAC_PN: { req: "783", res: "503", bus: "CAN_I" },
	ICS_PN: { req: "7BC", res: "53C", bus: "CAN_I" },
	IPC_PN: { req: "742", res: "4C2", bus: "CAN_C" },
	ITBM_PN: { req: "754", res: "4D4", bus: "CAN_C" },
	LBSS_PN: { req: "791", res: "511", bus: "CAN_I" },
	MSM_PN: { req: "78A", res: "50A", bus: "CAN_I" },
	MSMP_PN: { req: "795", res: "515", bus: "CAN_I" },
	OCM_PN: { req: "75E", res: "4DE", bus: "CAN_C" },
	ORC_PN: { req: "744", res: "4C4", bus: "CAN_C" },
	PCM: { req: "7E0", res: "7E8", bus: "CAN_C" },
	PDM_PN: { req: "785", res: "505", bus: "CAN_I" },
	PSSM: { req: "7B8", res: "51C", bus: "CAN_I" },
	PTS_PN: { req: "762", res: "4E2", bus: "CAN_C" },
	RBSS_PN: { req: "799", res: "519", bus: "CAN_I" },
	RFH_PN: { req: "740", res: "4C0", bus: "CAN_C" },
	RVCM: { req: "79D", res: "51D", bus: "CAN_I" },
	SCCM_PN: { req: "763", res: "4E3", bus: "CAN_C" },
	SGW: { req: "47E", res: "47F", bus: "CAN_C" },
	TCM: { req: "7E1", res: "7E9", bus: "CAN_C" },
	CMCM_PN: { req: "7BF", res: "53F", bus: "CAN_I" }
};

/**
 * ECUs
 */
export const DIAGNOSTICS = _.keys(DIAGNOSTICS_BUS_MAP);

/**
 * Map of bus req and res codes to ecu metadata.
 */
export const ECU_LOOKUP_TABLE = {
	"747-4C7": {
		name: "ABS_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300267"
	},
	"757-4D7": {
		name: "ADCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300268"
	},
	"768-4E8": {
		name: "AFLS_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300269"
	},
	"76C-4EC": {
		name: "AHCP_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300270"
	},
	"769-4E9": {
		name: "AHLM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300271"
	},
	"7BE-53E": {
		name: "AMP_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300272"
	},
	"7A5-525": {
		name: "ANC_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300273"
	},
	"74A-4CA": {
		name: "ASBS_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300274"
	},
	"766-4E6": {
		name: "ASCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300275"
	},
	"776-4F6": {
		name: "ATMM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300276"
	},
	"620-504": {
		name: "BCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300277"
	},
	"7E7-7EF": {
		name: "BPCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300278"
	},
	"7E4-7EC": {
		name: "BSMO",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300279"
	},
	"7B6-536": {
		name: "CDM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300280"
	},
	"7BF-53F": {
		name: "CMCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300281"
	},
	"796-516": {
		name: "CRSM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300282"
	},
	"792-512": {
		name: "CSWM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300283"
	},
	"794-514": {
		name: "CVPM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300284"
	},
	"753-4D3": {
		name: "DASM/ACC_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300285"
	},
	"7AC-52C": {
		name: "DCSD_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300286"
	},
	"784-504": {
		name: "DDM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300287"
	},
	"751-4D1": {
		name: "DPDM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300288"
	},
	"74B-4CB": {
		name: "DTCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300289"
	},
	"7B4-534": {
		name: "DTV_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300290"
	},
	"770-4F0": {
		name: "ELSD_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300291"
	},
	"75A-4DA": {
		name: "EPS_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300292"
	},
	"748-4C8": {
		name: "ESL_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300293"
	},
	"749-4C9": {
		name: "ESM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300294"
	},
	"764-4E4": {
		name: "HALF_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300295"
	},
	"7E2-7EA": {
		name: "HCP_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300296"
	},
	"783-503": {
		name: "HVAC_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300297"
	},
	"7BC-53C": {
		name: "ICS_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300298"
	},
	"76D-4ED": {
		name: "IDCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300299"
	},
	"742-4C2": {
		name: "IPC_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300300"
	},
	"7BF-50F": {
		name: "ITM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300301"
	},
	"791-511": {
		name: "LBSS_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300302"
	},
	"78A-50A": {
		name: "MSM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300303"
	},
	"75E-4DE": {
		name: "OCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300304"
	},
	"744-4C4": {
		name: "ORC_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300305"
	},
	"7E0-7E8": {
		name: "PCM",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300306"
	},
	"785-505": {
		name: "PDM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300307"
	},
	"78C-50C": {
		name: "PLGM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300308"
	},
	"793-513": {
		name: "PTCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300309"
	},
	"762-4E2": {
		name: "PTS_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300310"
	},
	"799-519": {
		name: "RBSS_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300311"
	},
	"740-4C0": {
		name: "RFH_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300312"
	},
	"763-4E3": {
		name: "SCCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300313"
	},
	"47E-47F": {
		name: "SGW_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300314"
	},
	"79D-51D": {
		name: "RVCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300315"
	},
	"7AB-52B": {
		name: "TBM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300316"
	},
	"7E1-7E9": {
		name: "TCM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300317"
	},
	"743-4C3": {
		name: "TPM_PN",
		hwVersion: "22F150",
		swVersion: "22F151",
		partNo: "22F132",
		supplier: "22F154",
		dtc_count: "19010D",
		dtcs: "19020D",
		policy: "4300318"
	}
};

/**
 * Axios does not export their cancelled response code anywhere, this is the code for a cancelled response from Axios
 */
export const AXIOS_CANCEL_CODE = "ERR_CANCELED";

/**
 * How long a refresh token is valid for (set on server)
 * 30 days or 2592000 seconds
 */
export const REFRESH_TOKEN_EXPIRATION_TIME = 2592000;

/**
 * The length of the VIN
 */
export const VIN_LENGTH = 17;
