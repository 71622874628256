import { IconButton, Typography, TypographyProps } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { Accordion } from "../Accordion";
import { Info } from "@mui/icons-material";
import React from "react";
import { InfoPanelSectionConfig } from "../../utils/infoPanelUtils/infoPanelConfig";
import { InfoPanelContextType } from "../../utils/infoPanelUtils/infoPanelContext";
import * as Styled from "./infoPanelStyles";

interface InfoPanelTypographyProps {
	/**
	 * The children for the typography
	 */
	children: React.ReactNode;
	/**
	 * An optional override for variant
	 * Default is h6
	 */
	variant?: Variant;
}

interface InfoPanelAccordionProps {
	/**
	 * The accordion title
	 */
	title: string;
	/**
	 * Additional props for the title
	 */
	titleProps?: Partial<TypographyProps>;
	/**
	 * The accordion content
	 */
	content: React.ReactNode;
	/**
	 * Child sections
	 */
	nestedSections?: Array<InfoPanelSectionConfig>;
	/**
	 * Optionally styles with less margin and padding for more dense element
	 * Nice for child elements
	 */
	isDense?: boolean;
	/**
	 * The open state for the accordion
	 * Optionally turns accordion into controlled accordion
	 */
	isOpen?: boolean;
}

/**
 * A reusable Typography component with a set variant
 */
const InfoPanelTypography: React.FC<InfoPanelTypographyProps> = (props) => {
	return <Typography variant={props.variant ?? "subtitle2"}>{props.children}</Typography>;
};

InfoPanelTypography.displayName = "InfoPanelTypography";

/**
 * The main implementation of the reusable accordion
 */
const InfoPanelAccordion: React.FC<InfoPanelAccordionProps> = (props) => {
	// The accordion keeps its own open state because it's generated by accordion configs
	const [openState, setOpenState] = React.useState(false);

	React.useEffect(() => {
		// Since isOpen could be undefined, only set if true
		if (props.isOpen === true) {
			setOpenState(true);
		}
	}, [props.isOpen]);

	return (
		<Styled.AccordionWrapper>
			<Accordion
				title={props.title}
				titleProps={{ variant: "h6", color: undefined, ...props.titleProps }}
				renderBody={() => props.content}
				accordionProps={{
					elevation: 0,
					disableGutters: props.isDense ? true : undefined,
					expanded: openState,
					onChange: () => setOpenState(!openState)
				}}
			/>
		</Styled.AccordionWrapper>
	);
};

InfoPanelAccordion.displayName = "InfoPanelAccordion";

/**
 * A spacer for between info panel section content
 * An alternative to a break because it adds space between paragraphs
 */
const InfoPanelSpacer: React.FC = () => {
	return <div style={{ height: ".5rem " }}></div>;
};

InfoPanelSpacer.displayName = "InfoPanelSpacer";

/**
 * Handles opening the info panel to a particular section
 * @param infoPanelContext the current info panel context
 * @param sectionTitle the title to open
 */
const openInfoPanelSection = (infoPanelContext: InfoPanelContextType, sectionTitle: string) => {
	infoPanelContext.setOpenState(true);
	infoPanelContext.setOpenSection(sectionTitle);
};

/**
 * The props for an info panel anchor instance
 */
interface AnchorProps {
	/**
	 * The current info panel context
	 */
	infoPanelContext: InfoPanelContextType;
	/**
	 * The title of the info panel section to anchor
	 */
	anchorTitle: string;
}

/**
 * Renders an anchor to a specific info panel section
 * @returns A rendered info button
 */
const InfoPanelAnchor: React.FC<AnchorProps> = (props) => {
	return (
		<IconButton
			sx={{ alignSelf: "flex-start" }}
			onClick={() => openInfoPanelSection(props.infoPanelContext, props.anchorTitle)}
		>
			<Info fontSize="small" />
		</IconButton>
	);
};

export default {
	InfoPanelAccordion,
	InfoPanelTypography,
	InfoPanelSpacer,
	openInfoPanelSection,
	InfoPanelAnchor
};
