import { blueGrey, cyan } from "@mui/material/colors";
import { PaletteColorOptions, SimplePaletteColorOptions, createTheme } from "@mui/material/styles";

// core fonts (applies to both standard and dark mode)
const fontFamily = `"Encode Sans Expanded", "Lato", sans-serif`;

// core colors (applies to both standard and dark themes)
const stellantisBlue = "#243782";
const white = "#ffffff";
const leftNavHoverColor = "rgba(255, 255, 255, 0.1)";
const fontColor = "#606c76"; // Grey

// standard theme colors (applies only to standard theme)
const standardBackgroundColor = "#f9f9f9";
const hoverColor = "#00838f"; // Darker shade of teal

// dark theme colors (applies only to dark theme)
const darkButtonColor = "#333333"; // Dark Grey
const darkFontColor = "#6878B5"; // Light Navy
const darkHoverColor = "#43aaa0"; // Teal
export const muiSecondaryTextColor = "#b8b8b8";

// Constants used to calculate styles across app
export const bottomNavHeight = "4.25rem";

declare module "@mui/material/styles" {
	interface PaletteColor {
		hover?: string;
	}

	interface SimplePaletteColorOptions {
		hover?: string;
	}
}

declare module "@mui/material/styles" {
	interface PaletteOptions {
		cyan?: PaletteColorOptions;
	}
	interface Palette {
		cyan: PaletteColor;
	}
}

/**
 * Can be used by specifying "primary.light", "primary.main"
 * in MUI component 'color={}' props.
 */
const StellantisDefaultPalette: SimplePaletteColorOptions = {
	light: fontColor,
	main: stellantisBlue,
	contrastText: white,
	hover: hoverColor
};

/**
 * Can be used by specifying "primary.light", "primary.main"
 * in MUI component 'color={}' props.
 */
const StellantisDarkPalette: SimplePaletteColorOptions = {
	light: fontColor,
	main: darkFontColor,
	contrastText: white,
	hover: darkHoverColor
};

/**
 * the standard (default) website theme
 */
export const standardTheme = createTheme({
	typography: {
		h1: {
			fontFamily: fontFamily
		},
		h2: {
			fontFamily: fontFamily
		},
		h3: {
			fontFamily: fontFamily
		},
		h4: {
			fontFamily: fontFamily
		},
		h5: {
			fontFamily: fontFamily
		},
		h6: {
			fontFamily: fontFamily
		}
	},
	palette: {
		primary: StellantisDefaultPalette,
		info: {
			main: stellantisBlue
		},
		background: {
			default: standardBackgroundColor
		},
		cyan: {
			main: cyan[600],
			light: cyan[300],
			dark: cyan[900],
			contrastText: "#ffffff"
		  }
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				h1: {
					fontFamily: fontFamily
				},
				h2: {
					fontFamily: fontFamily
				},
				h3: {
					fontFamily: fontFamily
				},
				h4: {
					fontFamily: fontFamily
				},
				h5: {
					fontFamily: fontFamily
				},
				h6: {
					fontFamily: fontFamily
				}
			}
		},
		MuiChip: {
			styleOverrides: {
				label: {
					color: hoverColor
				}
			}
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: "none",
					color: hoverColor + " !important",
					"&:hover": {
						color: stellantisBlue + " !important"
					}
				}
			}
		}
	}
});

/**
 * the dark website theme
 */
export const darkTheme = createTheme({
	typography: {
		h1: {
			fontFamily: fontFamily
		},
		h2: {
			fontFamily: fontFamily
		},
		h3: {
			fontFamily: fontFamily
		},
		h4: {
			fontFamily: fontFamily
		},
		h5: {
			fontFamily: fontFamily
		},
		h6: {
			fontFamily: fontFamily
		}
	},
	palette: {
		primary: StellantisDarkPalette,
		mode: "dark",
		background: {
			// default: custom override here
		},
		text: {
			primary: muiSecondaryTextColor // using mui's secondary text color for primary, default white seems too vibrant?
		},
		success: {
			// blueGrey until we find a better color? Or default to MUI's green[400]?
			main: blueGrey[700]
		},
		cyan: {
			main: cyan[500],
			light: cyan[300],
			dark: cyan[700],
			contrastText: "#ffffff"
		  }
	},
	components: {
		MuiButton: {
			styleOverrides: {
				containedPrimary: {
					textPrimary: white,
					color: white,
					backgroundColor: darkButtonColor,
					"&:hover": {
						backgroundColor: leftNavHoverColor
					}
				},
				textPrimary: {
					textPrimary: white,
					color: white,
					"&:hover": {
						backgroundColor: leftNavHoverColor
					}
				}
			}
		},
		MuiFab: {
			styleOverrides: {
				root: {},
				primary: {
					color: white,
					backgroundColor: darkButtonColor,
					"&:hover": {
						backgroundColor: leftNavHoverColor
					}
				}
			}
		},
		MuiTypography: {
			styleOverrides: {
				h1: {
					fontFamily: fontFamily,
					color: fontColor
				},
				h2: {
					fontFamily: fontFamily,
					color: fontColor
				},
				h3: {
					fontFamily: fontFamily,
					color: fontColor
				},
				h4: {
					fontFamily: fontFamily,
					color: fontColor
				},
				h5: {
					fontFamily: fontFamily,
					color: fontColor
				},
				h6: {
					fontFamily: fontFamily,
					color: fontColor
				}
			}
		},
		MuiChip: {
			styleOverrides: {
				label: {
					color: darkHoverColor
				}
			}
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: "none",
					color: darkHoverColor + " !important",
					"&:hover": {
						color: darkFontColor + " !important"
					}
				}
			}
		}
	}
});
