import * as Material from "@mui/material";
import _ from "lodash";
import React from "react";
import { $enum } from "ts-enum-util";
import StyledTextLink from "../../components/StyledTextLink";
import { Regions } from "../../utils/regionUtils";
import * as Styled from "./landingPageStyles";

/**
 * Props interface for this component.
 */
interface Props {
	/**
	 * Called when the user submits their request.
	 * @param userGroup - the organization the user belongs to.
	 * @param selectedRegions - the region(s) for which they need access to
	 * @param businessJustification - the business justification.
	 */
	onSubmit(
		userGroup: string,
		selectedRegions: Regions[],
		businessJustification: string,
		isReqArea51: boolean
	): void;
}

/**
 * Intent enum for the user's interest in ADA
 */
enum Intent {
	View = "view",
	Configure = "configure",
	Create = "create",
	Area51 = "area51",
	Other = "other"
}

/**
 * Reference link for the user to learn more about GCSI
 */
const GCSI_REF_LINK =
	"https://shiftup.sharepoint.com/:w:/s/ConnectedVehicleSoftware-COSS/ER6fjlSO73xFiWFXGEC2UesBQZEWI8KQ3TGHEj-16F4fKA?e=1eI39s";

/**
 * Reference link for the user to learn more about DataLake
 */
const DATABRICKS_REF_LINK =
	"https://stla-swx-confluence.atlassian.net/wiki/spaces/GADP/pages/279413436/Databricks+-+Access+First+Steps";

/**
 * Reference link for the user to learn more about ADA
 */
const REVM_CONFLUENCE_LINK =
	"https://stla-swx-confluence.atlassian.net/wiki/spaces/CS/overview?homepageId=45318568";

/**
 * Reference link for the user to learn more about ADA data collection
 */
const ADA_101_REF_LINK =
	"https://shiftup.sharepoint.com/:p:/s/ConnectedVehicleSoftware-COSS/EZLBr_m1sm5HuipOg5vj1kMBQmjMEj-Y8JkwOX2ws2cavQ?e=EicsmH";

/**
 * Reference link to ADA Web Integration Portal instance
 */
const ADA_WEB_INT_REF_LINK = "https://dev.alldataapp.com/";

/**
 * Reference link to ADA Web Preprod Portal instance
 */
const ADA_WEB_PREPROD_REF_LINK = "https://stg.alldataapp.com/";

/**
 * Reference link to ADA Confluence Support page
 */
const ADA_CONFLUENCE_SUPPORT_REF_LINK =
	"https://shiftup-my.sharepoint.com/:b:/g/personal/t8414lr_inetpsa_com/Edsk2U21fhVDpGs-UjCImNABfYTfb-0NKYC8jb-SlA-9oA?e=IYyOfF";

const ADA_POLICY_CREATION_GUIDE_REF_LINK =
	"https://shiftup.sharepoint.com/:w:/s/ConnectedVehicleSoftware-COSS/EYXdu87uopxKhbNd7AtYQLQBastMQJlXxizXG3Ms6A34HQ?e=Ieqs2W";

/**
 * Interactive version of {@link AccessRequestView} meant to be
 * used for production only
 * @returns - the rendered component.
 */
export const InteractiveAccessRequestForm: React.FC<Props> = (props) => {
	const [selectedRegions, setSelectedRegions] = React.useState<Array<Regions>>([]);
	const [userGroup, setUserGroup] = React.useState<string>("");
	const [businessJustification, setBusinessJustification] = React.useState<string>("");
	const [userInterest, setUserInterest] = React.useState<Intent>(Intent.View);
	const [isArea51Selected, setIsArea51Selected] = React.useState<boolean>(false);

	const isFormValid =
		isArea51Selected ||
		(_.size(userGroup.trim()) > 0 &&
			_.size(businessJustification.trim()) > 0 &&
			!_.isEmpty(selectedRegions));

	return (
		<Styled.AccessRequestView>
			<Styled.AccessRequestForm>
				<Material.FormLabel required={true}>What brings you here?</Material.FormLabel>
				<Material.RadioGroup
					aria-labelledby="what-brings-you-here-radio-group-label"
					defaultValue="view"
					name="radio-buttons-group"
					onChange={(event) => {
						setUserInterest(event.target.value as Intent);
						setIsArea51Selected(event.target.value === Intent.Area51);
					}}
				>
					<Material.FormControlLabel
						value={Intent.View}
						control={<Material.Radio />}
						label="I only want to view data"
					/>
					<Material.FormControlLabel
						value={Intent.Other}
						control={<Material.Radio />}
						label="I am interested in ADA and want to learn more"
					/>
					<Material.FormControlLabel
						value={Intent.Create}
						control={<Material.Radio />}
						label="I want to write policies"
					/>
					<Material.FormControlLabel
						value={Intent.Configure}
						control={<Material.Radio />}
						label="I need to configure VINs for data collection"
					/>
					<Material.FormControlLabel
						value={Intent.Area51}
						control={<Material.Radio />}
						label="I want access to Area51"
					/>
				</Material.RadioGroup>
			</Styled.AccessRequestForm>
			{userInterest === Intent.View && (
				<Material.Fade in={true} timeout={750}>
					<Styled.DidYouKnowContainer>
						<Styled.DidYouKnowTitle variant="h6">Did You Know?</Styled.DidYouKnowTitle>
						<Material.Typography variant="body1">
							To view ADA PRODUCTION data please refer to the following applications.
							For access to these applications, please reach out to the respective
							application support teams as specified in the links below.
						</Material.Typography>
						<Material.List>
							<Material.ListItem>
								<Material.Typography variant="body1">
									<Material.Link
										href={GCSI_REF_LINK}
										target="_blank"
										rel="noopener noreferrer"
									>
										GCSI
									</Material.Link>
								</Material.Typography>
							</Material.ListItem>
							<Material.ListItem>
								<Material.Typography variant="body1">
									<StyledTextLink
										href={DATABRICKS_REF_LINK}
										target="_blank"
										rel="noopener noreferrer"
										showExternalLinkIcon
										variant="body1"
									>
										Databricks
									</StyledTextLink>
								</Material.Typography>
							</Material.ListItem>
						</Material.List>
						<Material.Typography variant="body1">
							To view ADA DEV (Integration) or ADA STAGE (Preproduction) data, visit
							the respective links below:
						</Material.Typography>
						<Material.List>
							<Material.ListItem>
								<Material.Typography variant="body1">
									<Material.Link
										href={ADA_WEB_INT_REF_LINK}
										target="_blank"
										rel="noopener noreferrer"
									>
										ADA DEV Web Portal
									</Material.Link>
								</Material.Typography>
							</Material.ListItem>
							<Material.ListItem>
								<Material.Typography variant="body1">
									<Material.Link
										href={ADA_WEB_PREPROD_REF_LINK}
										target="_blank"
										rel="noopener noreferrer"
									>
										ADA STAGE Web Portal
									</Material.Link>
								</Material.Typography>
							</Material.ListItem>
						</Material.List>
					</Styled.DidYouKnowContainer>
				</Material.Fade>
			)}
			{userInterest === Intent.Other && (
				<Material.Fade in={true} timeout={750}>
					<Styled.DidYouKnowContainer>
						<Styled.DidYouKnowTitle variant="h6">Did You Know?</Styled.DidYouKnowTitle>
						<Material.Typography variant="body1">
							ADA has some great resources to help you learn more. Check out the
							resources below.
						</Material.Typography>
						<Material.List>
							<Material.ListItem>
								<Material.Typography variant="body1">
									<StyledTextLink
										href={REVM_CONFLUENCE_LINK}
										target="_blank"
										rel="noopener noreferrer"
										variant="body1"
										showExternalLinkIcon
									>
										REVM Confluence Space
									</StyledTextLink>
									- where you can learn more about the various parts of ADA
								</Material.Typography>
							</Material.ListItem>
							<Material.ListItem>
								<Material.Typography variant="body1">
									<Material.Link
										href={ADA_101_REF_LINK}
										target="_blank"
										rel="noopener noreferrer"
									>
										ADA 101
									</Material.Link>{" "}
									- to help you get familiar with using ADA to collect vehicle
									data
								</Material.Typography>
							</Material.ListItem>
						</Material.List>
						<Material.Typography variant="body1">
							Having problems accessing Confluence? Visit this{" "}
							<Material.Link
								href={ADA_CONFLUENCE_SUPPORT_REF_LINK}
								target="_blank"
								rel="noopener noreferrer"
							>
								Confluence Support
							</Material.Link>{" "}
							documentation for help.
						</Material.Typography>
					</Styled.DidYouKnowContainer>
				</Material.Fade>
			)}
			{userInterest === Intent.Create && (
				<Material.Fade in={true} timeout={750}>
					<Styled.DidYouKnowContainer>
						<Styled.DidYouKnowTitle variant="h6">Did You Know?</Styled.DidYouKnowTitle>
						<Material.Typography variant="body1">
							To create policies for ADA, please visit and request access to our{" "}
							<Material.Link
								href={ADA_WEB_INT_REF_LINK}
								target="_blank"
								rel="noopener noreferrer"
							>
								ADA DEV (INTEGRATION)
							</Material.Link>{" "}
							or{" "}
							<Material.Link
								href={ADA_WEB_PREPROD_REF_LINK}
								target="_blank"
								rel="noopener noreferrer"
							>
								ADA STAGE (PREPRODUCTION)
							</Material.Link>{" "}
							environment.
						</Material.Typography>
						<br />
						<Material.Typography variant="body1">
							Here is a helpful{" "}
							<Material.Link
								href={ADA_POLICY_CREATION_GUIDE_REF_LINK}
								target="_blank"
								rel="noopener noreferrer"
							>
								Policy Creation Guide
							</Material.Link>{" "}
							to kickstart your policy creation journey once you've been granted
							access.
						</Material.Typography>
					</Styled.DidYouKnowContainer>
				</Material.Fade>
			)}
			{userInterest === Intent.Configure && (
				<Material.Fade in={true} timeout={750}>
					<Material.Box>
						<Styled.AccessRequestForm>
							<Material.FormLabel required={true}>
								Select the region(s) you need access to
							</Material.FormLabel>
							{$enum(Regions).map((region) => {
								return (
									<Material.FormControlLabel
										key={region}
										control={
											<Material.Checkbox
												checked={selectedRegions.includes(region)}
												onChange={(event) => {
													const updatedRegions = event.target.checked
														? [...selectedRegions, region]
														: selectedRegions.filter(
																(selectedRegion) =>
																	selectedRegion !== region
														  );

													setSelectedRegions(updatedRegions);
												}}
												value={region}
											/>
										}
										label={region}
									/>
								);
							})}
						</Styled.AccessRequestForm>
						<Styled.TopSpacer>
							<Material.TextField
								sx={{ width: { sm: 250, md: 500 } }}
								required={true}
								label={"What org do you belong to? (e.g. SWX/SWE/CSX/REVM/CSBT)"}
								type="text"
								variant="filled"
								value={userGroup}
								onChange={(event) => {
									setUserGroup(event.target.value);
								}}
							/>
						</Styled.TopSpacer>
						<Styled.TopSpacer>
							<Material.TextField
								sx={{ width: { sm: 250, md: 500 } }}
								required={true}
								multiline={true}
								minRows={2}
								label={
									"Provide any other information that will help us determine your access level"
								}
								type="text"
								variant="filled"
								value={businessJustification}
								onChange={(event) => {
									setBusinessJustification(event.target.value);
								}}
							/>
						</Styled.TopSpacer>
					</Material.Box>
				</Material.Fade>
			)}
			{userInterest === Intent.Area51 && (
				<Material.Fade in={true} timeout={750}>
					<Material.Box>
						<Styled.TopSpacer>
							<Material.TextField
								sx={{ width: { sm: 250, md: 500 } }}
								required={true}
								multiline={true}
								minRows={2}
								label={
									"Provide any other information that will help us determine your access level"
								}
								type="text"
								variant="filled"
								value={businessJustification}
								onChange={(event) => {
									setBusinessJustification(event.target.value);
								}}
							/>
						</Styled.TopSpacer>
					</Material.Box>
				</Material.Fade>
			)}
			<Styled.AccessRequestSubmitButton
				variant="contained"
				color="primary"
				fullWidth={false}
				disabled={!isFormValid}
				onClick={() => {
					props.onSubmit(
						userGroup,
						selectedRegions,
						businessJustification,
						isArea51Selected
					);
				}}
			>
				Submit
			</Styled.AccessRequestSubmitButton>
		</Styled.AccessRequestView>
	);
};
