import { Close, Info } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { InfoPanelSectionConfig } from "../../utils/infoPanelUtils/infoPanelConfig";
import { InfoPanelContext } from "../../utils/infoPanelUtils/infoPanelContext";
import * as Styled from "./infoPanelStyles";
import { GlowingIconButton } from "./infoPanelStyles";
import ReusableComponents from "./reusableInfoPanelComponents";

const COMPONENT_NAME = "InfoPanel";

/**
 * The props for the component
 */
interface Props {
	/**
	 * The open state of the drawer
	 */
	isOpen: boolean;
	/**
	 * The set state for the parent to toggle the open state of the drawer
	 */
	setIsOpen: (newOpenState: boolean) => void;
}

/**
 * The vProp for controlling the highlight
 */
export type HighlightProp = {
	/**
	 * Controls if the IconButton should be highlighted
	 */
	isHighlighted: boolean;
}

/**
 * The main implementation of the info panel
 */
const InfoPanel: React.FC<Props & HighlightProp> = (props) => {
	const { isOpen, setIsOpen, isHighlighted } = props;
	const infoPanelContext = React.useContext(InfoPanelContext);

	const [openSection, setOpenSection] = React.useState<string | undefined>(undefined);

	React.useEffect(() => {
		setOpenSection(infoPanelContext.openSection);
	}, [infoPanelContext.openSection]);

	/**
	 * Helper to render section content and nested sections
	 * @param section the section to render
	 * @returns the rendered content and children
	 */
	const renderSectionContent = (section: InfoPanelSectionConfig) => {
		return (
			<>
				{section.content}
				{section.nestedSections && renderSections(section.nestedSections)}
			</>
		);
	};

	/**
	 * Renders a non-expanding info panel section and children
	 * @param section the section to render
	 * @returns returns a non-expanding section
	 */
	const renderSection = (section: InfoPanelSectionConfig) => {
		return (
			<Box display="flex" flexDirection="column" sx={{ padding: Styled.defaultPadding }}>
				<Typography variant="h6">{section.displayTitle ?? section.title}</Typography>
				{renderSectionContent(section)}
			</Box>
		);
	};

	/**
	 * Renders an accordion info panel section and children
	 * @param section the section to render
	 * @returns returns an accordion section
	 */
	const renderSectionAccordion = (section: InfoPanelSectionConfig) => {
		/**
		 * A helper function to recursively check for nested children
		 * @param sections the sections to check for matching title
		 * @returns boolean determining if any nested children contain the title
		 */
		const doChildrenContainTitle = (sections: Array<InfoPanelSectionConfig>) => {
			return _.some(sections, (section: InfoPanelSectionConfig): boolean =>
				section.nestedSections
					? doChildrenContainTitle(section.nestedSections) // Open if any children contain title
					: _.some(
							sections,
							(section: InfoPanelSectionConfig) => section.title === openSection // Or if section itself has title
					  )
			);
		};
		return (
			<ReusableComponents.InfoPanelAccordion
				title={section.displayTitle ?? section.title}
				content={renderSectionContent(section)}
				isDense={true}
				// If any nested children contain the title all parents need to open
				isOpen={
					openSection === section.title ||
					(section.nestedSections && doChildrenContainTitle(section.nestedSections))
				}
			/>
		);
	};

	/**
	 * Renders sections for each section in info panel config
	 * @param sections the sections to render
	 * @returns the rendered sections
	 */
	const renderSections = (sections: Array<InfoPanelSectionConfig>): React.ReactNode => {
		return (
			<>
				{_.map(sections, (section, index) => {
					return section.isAccordion ? (
						<div key={section.title + index}>{renderSectionAccordion(section)}</div>
					) : (
						<div key={section.title + index}>{renderSection(section)}</div>
					);
				})}
			</>
		);
	};

	return (
		<Styled.InfoPanel color="inherit" id="info-drawer" isOpen={props.isOpen}>
			<Styled.ExpandedPanel>
				<Box display="flex" flexDirection="column" sx={{ height: "100%" }}>
					<Styled.Header>
						<Typography variant="h5" sx={{ fontWeight: "bold" }}>
							{infoPanelContext.title}
						</Typography>
						<IconButton onClick={() => props.setIsOpen(false)}>
							<Close />
						</IconButton>
					</Styled.Header>
					<Divider />
					{renderSections(infoPanelContext.sections)}
					{/* The Take Survey button */}
					<Box sx={{ padding: Styled.defaultPadding }}>
						<Button
							variant="outlined"
							color="primary"
							fullWidth
							onClick={() => {
								window.open("https://forms.office.com/e/QqZSn61Qfp", "_blank");
							}}
						>
							Take Survey
						</Button>
					</Box>
				</Box>
			</Styled.ExpandedPanel>
			<Styled.PersistentPanel>
				{isHighlighted ? (
					<GlowingIconButton color="primary" onClick={() => setIsOpen(!isOpen)}>
						<Info />
					</GlowingIconButton>
				) : (
					<IconButton color="primary" onClick={() => setIsOpen(!isOpen)}>
						<Info />
					</IconButton>
				)}
			</Styled.PersistentPanel>
		</Styled.InfoPanel>
	);
};

InfoPanel.displayName = COMPONENT_NAME;

export default InfoPanel;
